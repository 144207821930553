import React from 'react';
import Panel from './Panel';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { trimFuturesSymbols } from 'utils/functions';
import {
  DATA_SOURCE_FUTURES,
  DATA_SOURCE_INDEX,
  FUTURES_SYMBOL_CONVERSION,
} from 'utils/constants';

const StyledSlider = styled(Slider)`
  .slick-track {
    top: 0.1875rem;
  }

  .slick-dots {
    li {
      button {
        &:before {
          color: ${prop('theme.colors.redPrimary')};
          font-size: 0.875rem;
        }
      }
    }
  }
`;

const propTypes = {
  data: PropTypes.object,
  symbolObject: PropTypes.object,
  prismicSymbol: PropTypes.string,
  prismicTitle: PropTypes.string,
};

const defaultProps = {
  data: {},
  symbolObject: {},
  prismicTitle: '',
  prismicSymbol: '',
};

function CarouselPanels({ data, symbolObject, dataSource }) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    accessibility: false,
  };

  return (
    data && (
      <StyledSlider {...settings}>
        {Object.keys(data).map(feed => {
          const trimSymbol = trimFuturesSymbols(feed);

          return (
            <Panel
              key={`panel_${feed}`}
              data={data[feed]}
              futuresData={
                data[feed].Profile.dataSource === DATA_SOURCE_FUTURES
              }
              prismicSymbol={
                data[feed].Profile.dataSource === DATA_SOURCE_FUTURES
                  ? symbolObject[trimSymbol] && symbolObject[trimSymbol].symbol
                  : symbolObject[feed] && symbolObject[feed].symbol
              }
              prismicTitle={
                data[feed].Profile.dataSource === DATA_SOURCE_FUTURES
                  ? symbolObject[trimSymbol] && symbolObject[trimSymbol].title
                  : symbolObject[feed] && symbolObject[feed].title
              }
            />
          );
        })}
      </StyledSlider>
    )
  );
}

CarouselPanels.propTypes = propTypes;
CarouselPanels.defaultProps = defaultProps;

export default CarouselPanels;
