import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { graphql, StaticQuery } from 'gatsby';
import first from 'lodash/first';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageBody from '../components/PageBody';
import PriceFeeds from '../components/PriceFeeds';
import MainCol from '../components/PageBody/MainCol';
import SideRailsMenu from '../components/SideRailsMenu';
import BannerBlockJumbotron from '../components/BannerBlockJumbotron';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

const defaultProps = {
  data: null,
  errors: [],
};

function Homepage() {
  return (
    <StaticQuery
      query={`${homepageQuery}`}
      render={data => {
        const pageData = first(data.prismic.allHomepages.edges);

        if (!pageData) {
          return null;
        }

        const {
          seo_title,
          seo_meta_description,
          page_title,
          page_description,
          _meta,
          body,
          header_banner,
        } = pageData.node;
        const pageTitle = page_title ? page_title[0].text : '';
        const pageDescription =
          page_description && page_description.length !== 0
            ? page_description[0].text
            : '';

        return (
          <Layout>
            <SEO
              title={seo_title || pageName}
              description={seo_meta_description || pageDescription}
              lang={_meta}
            />
            <BannerBlockJumbotron headerBanner={first(header_banner)} />
            <Row>
              <SideRailsMenu title={pageTitle} data={body} />
              <MainCol lg={7} md={12}>
                <ColumnWrapper>
                  {pageDescription && <p>{pageDescription}</p>}
                  {body && <PageBody body={body} template={'homepage'} />}
                </ColumnWrapper>
              </MainCol>
              <PriceFeeds />
            </Row>
          </Layout>
        );
      }}
    />
  );
}

const homepageQuery = graphql`
  query homeQuery {
    prismic {
      allHomepages {
        edges {
          node {
            header_banner {
              image
              sub_title
              title
              button_text
              embed_video
            }
            _meta {
              lang
            }
            seo_title
            seo_meta_description
            page_title
            body {
              ... on PRISMIC_HomepageBodyEmbed_video {
                type
                label
                primary {
                  embed_video
                }
              }
              ... on PRISMIC_HomepageBodyLimited_time_offer {
                type
                label
                primary {
                  background_image
                  offer_list
                  text_block
                  offer_type
                  offer_button {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyText_block {
                type
                primary {
                  text_block_header
                }
                fields {
                  text_block_content
                }
              }
              ... on PRISMIC_HomepageBodyTwo_columns {
                type
                fields {
                  text_block
                  title
                  image
                  image_display_style
                }
              }
              ... on PRISMIC_HomepageBodyTrade_the_smalls {
                type
                label
                primary {
                  main_content
                  disclaimer
                  title
                  mailchimp_id
                }
                fields {
                  broker_logo
                }
              }
              ... on PRISMIC_HomepageBodyTwo_columns_image {
                type
                fields {
                  image
                  title
                  text_block
                }
              }
              ... on PRISMIC_HomepageBodyTable__10_cols_ {
                type
                primary {
                  table_title
                  table_description
                  col1
                  col2
                  col3
                  col4
                  col5
                  col6
                  col7
                  col8
                  col9
                  col10
                }
                fields {
                  col1_data
                  col2_data
                  col3_data
                  col4_data
                  col5_data
                  col6_data
                  col7_data
                  col8_data
                  col9_data
                  col10_data
                }
              }
            }
          }
        }
      }
    }
  }
`;

Homepage.propTypes = propTypes;
Homepage.defaultProps = defaultProps;

export default Homepage;
