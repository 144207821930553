import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col } from 'reactstrap';
import { graphql, StaticQuery } from 'gatsby';
import Feeds from './Feeds';
import first from 'lodash/first';
import theme from '../../styles/theme';
import stickybits from 'stickybits';
import { prop } from 'styled-tools';
import SiteSettingsContext from 'context/SiteSettingsContext';
import {
  DATA_SOURCE_FUTURES,
  DATA_SOURCE_INDEX,
  PRODUCT_DATA_SOURCE_GLOBAL,
} from 'utils/constants';

const PriceFeedColumn = styled(Col)`
  padding-top: 1.5rem;
`;

const PriceFeedsWrapper = styled.div`
  margin-left: auto;
  max-width: 15rem;
  padding: 0 1.4rem;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
  }
`;

function PriceFeeds() {
  const [isMobile, setIsMobile] = useState(false);
  const siteSettings = useContext(SiteSettingsContext);

  // Determine whether we show index data or futures data based on global site setting value.
  const dataSource = symbol => {
    return (
      siteSettings.products[symbol].price_cards_data_source || DATA_SOURCE_INDEX
    );
  };

  useEffect(() => {
    let stickybitsInstance;
    let currentMobile = isMobile;

    const onResize = () => {
      const smallBreakpoint = parseInt(theme.breakpoints.lg, 10);
      const vw = window.innerWidth;
      const newValue = vw <= smallBreakpoint;

      if (newValue !== currentMobile) {
        setIsMobile(newValue);
        currentMobile = newValue;
      }

      if (newValue) {
        // Remove stickybits in mobile.
        if (stickybitsInstance) {
          stickybitsInstance.cleanup();
          stickybitsInstance = null;
        }
      } else {
        if (stickybitsInstance) {
          stickybitsInstance.update();
        } else {
          // Use stickybits as a position: sticky; polyfill for IE11.
          stickybitsInstance = stickybits('#priceFeedsWrapper', {
            stickyBitStickyOffset: 128,
          });
        }
      }
    };

    if (window) {
      onResize();
      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);

      if (stickybitsInstance) {
        stickybitsInstance.cleanup();
      }
    };
  }, [isMobile]);

  return (
    <PriceFeedColumn lg={3} md={12}>
      <PriceFeedsWrapper id={'priceFeedsWrapper'}>
        <StaticQuery
          query={`${priceFeedsQuery}`}
          render={data => {
            const symbolData = data && data.prismic.allProducts.edges;
            const indexTooltip = first(symbolData).node.index_price_tooltip;
            const symbolObject =
              symbolData &&
              symbolData.reduce(
                (obj, cur, i) => ({
                  ...obj,
                  [cur.node.price_cards_data_source ===
                  PRODUCT_DATA_SOURCE_GLOBAL
                    ? siteSettings.global.price_cards_data_source ===
                      DATA_SOURCE_INDEX
                      ? cur.node.dxfeed_symbol
                      : cur.node.futures_symbol
                    : cur.node.price_cards_data_source === DATA_SOURCE_INDEX
                    ? cur.node.dxfeed_symbol
                    : cur.node.futures_symbol]: {
                    symbol:
                      dataSource(cur.node.dxfeed_symbol) === DATA_SOURCE_INDEX
                        ? cur.node.index_symbol
                        : cur.node.futures_symbol,
                    title: first(cur.node.title).text,
                    sort_order: cur.node.sort_order,
                    meta: cur.node._meta,
                    data_source: dataSource(cur.node.dxfeed_symbol),
                  },
                }),
                {}
              );

            return (
              <Feeds
                indexTooltip={indexTooltip}
                isMobile={isMobile}
                symbolObject={symbolObject}
              />
            );
          }}
        />
      </PriceFeedsWrapper>
    </PriceFeedColumn>
  );
}

export const priceFeedsQuery = graphql`
  query PriceFeedsQuery {
    prismic {
      allProducts {
        edges {
          node {
            title
            dxfeed_symbol
            futures_symbol
            index_symbol
            index_price_tooltip
            price_cards_data_source
            sort_order
            _meta {
              id
              type
              uid
            }
          }
        }
      }
    }
  }
`;

export default PriceFeeds;
