import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Jumbotron } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from 'utils/linkResolver';

const StyledBannerBlockJumbotron = styled(Jumbotron)`
  align-items: center;
  background-color: ${prop('theme.colors.greySky')};
  background-image: url('${prop('url')}');
  border-radius: 0.9375rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  justify-content: left;
  margin-bottom: 2rem;
  margin-top: 3rem;
  padding: 12.5rem 1rem;
  text-align: left;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 5rem;
    margin-bottom: 0;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    padding: 1.8rem 1rem;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    display: none;
  }
`;

const BannerBlockJumbotronHeading = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${prop('theme.fonts.default')};
  font-weight: ${prop('theme.fontWeights.light')};
  line-height: normal;
  margin-bottom: 0;
  font-size: 2.375rem;
  color: ${prop('theme.colors.blackErie')};

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    font-size: 1.8rem;
  }
`;

const propTypes = {
  headerBanner: PropTypes.object.isRequired,
};

function BannerBlockJumbotron({ headerBanner }) {
  const { title, sub_title, image } = headerBanner;

  return (
    <StyledBannerBlockJumbotron url={image.url}>
      <Row>
        <Col sm={{ size: 6, offset: 6 }}>
          <BannerBlockJumbotronHeading>
            {RichText.render(title, linkResolver)}
          </BannerBlockJumbotronHeading>
          {RichText.render(sub_title, linkResolver)}
        </Col>
      </Row>
    </StyledBannerBlockJumbotron>
  );
}

BannerBlockJumbotron.propTypes = propTypes;

export default BannerBlockJumbotron;
