import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { linkResolver } from 'utils/linkResolver';
import MenuLink from '../SideRailsMenu/styled/MenuLink';

const PanelContainer = styled(MenuLink)`
  box-shadow: -1px 1px 0.375rem rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  min-height: 6rem;
  padding: 1rem 0.7rem;

  &:hover {
    box-shadow: -1px 1px 0.375rem rgba(0, 0, 0, 0.411);
  }
`;

const PanelNameContainer = styled.div`
  line-height: 0.875rem;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    line-height: 1.5rem;
    text-align: center;
  }
`;

const PanelName = styled.h4`
  &&& {
    font-size: 0.78rem;
    letter-spacing: 0;
    margin-bottom: 0;
    padding: 0;
    line-height: 1;
    text-transform: unset;

    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      font-size: 1rem;
    }
  }
`;

const PanelSubName = styled.span`
  font-size: 0.6875rem;
  font-weight: ${prop('theme.fontWeights.regular')};
  margin: 0;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    font-size: 0.75rem;
  }
`;

const RightContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    align-items: center;
    margin-top: 0.5rem;
  }
`;

const PriceContent = styled.p`
  &&& {
    font-size: 1.25rem;
    font-weight: ${prop('theme.fontWeights.light')};
    letter-spacing: 0;
    margin: 0;
  }
`;

const ChangeContent = styled.p`
  &&& {
    font-size: 0.625rem;
    letter-spacing: 0;
    line-height: normal;
    margin: 0;
  }
`;

const StyledIndicator = styled(FontAwesomeIcon)`
  &&& {
    margin-right: 0.25rem;
    width: 1rem;
  }
`;

const propTypes = {
  data: PropTypes.object,
  futuresData: PropTypes.bool,
  prismicSymbol: PropTypes.string,
  prismicTitle: PropTypes.string,
};

const defaultProps = {
  data: {},
  futuresData: true,
  prismicSymbol: '',
  prismicTitle: '',
};

function Panel({
  data: { Trade, Profile, Summary },
  futuresData,
  prismicTitle,
  prismicSymbol,
}) {
  if (!Profile || !Trade || !Summary) {
    return <React.Fragment />;
  }

  const { dayVolume, eventSymbol } = Trade;
  let change;
  let changePercent;

  // Added additional checks in here b/c dxfeed often returns 'NaN' when there
  // is no data rather than undefined or null
  const currentPrice = !isNaN(get(Trade, 'price', 0))
    ? get(Trade, 'price', 0)
    : 0;
  const dayOpenPrice = !isNaN(get(Summary, 'dayOpenPrice'))
    ? get(Summary, 'dayOpenPrice')
    : 0;
  const prevPrice = !isNaN(get(Summary, 'prevDayClosePrice'))
    ? get(Summary, 'prevDayClosePrice')
    : dayOpenPrice;
  const url = linkResolver(Profile && Profile.meta);
  const notTrading =
    dayVolume === 0 && eventSymbol[0] === '/' && currentPrice === 0;

  // When there hasn't been any activity on a contract, like when the back month just becomes available.
  if (notTrading) {
    change = 0;
    changePercent = 0;
  } else {
    change = currentPrice - prevPrice;
    changePercent = isFinite(change / prevPrice)
      ? (change / prevPrice) * 100
      : 0;
  }

  return (
    <PanelContainer to={url} type='stockCard'>
      <PanelNameContainer>
        <PanelName>
          {prismicTitle}
          {futuresData ? '' : ' Index'}
        </PanelName>
        <PanelSubName>{prismicSymbol}</PanelSubName>
      </PanelNameContainer>
      <RightContent>
        {Trade.price && (
          <PriceContent>
            {Trade.price !== 'NaN'
              ? currentPrice.toFixed(2)
              : prevPrice.toFixed(2)}
          </PriceContent>
        )}
        <ChangeContent>
          {change !== 0 && (
            <Fragment>
              {change > 0 && (
                <StyledIndicator
                  icon={faSortUp}
                  style={{ color: 'green' }}
                  transform='down-8 grow-18'
                />
              )}
              {change < 0 && (
                <StyledIndicator
                  icon={faSortDown}
                  style={{ color: 'red' }}
                  transform='up-8 grow-18'
                />
              )}
            </Fragment>
          )}
          {change.toFixed(2).toString()} ({changePercent.toFixed(2)}%)
        </ChangeContent>
      </RightContent>
    </PanelContainer>
  );
}

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;

export default Panel;
