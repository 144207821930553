import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import first from 'lodash/first';
import last from 'lodash/last';
import Panel from './Panel';
import Tooltip from '../Tooltip';
import CarouselPanels from './CarouselPanels';
import SideRailHeader from '../SideRailsMenu/styled/SideRailHeader';
import { TimeFrameLink } from '../Widgets/HistoricalGraph';
import useSocket from '../../hooks/useSocket';
import {
  formatTime,
  findLastTradeTime,
  buildFuturesBySymbol,
  findContractExpirationDate,
  findFrontBackMonth,
  trimFuturesSymbols,
} from '../../utils/functions';
import { DATA_SOURCE_FUTURES, DATA_SOURCE_INDEX } from 'utils/constants';

const IndexPriceHeader = styled(SideRailHeader)`
  position: relative;
  &&& {
    margin-bottom: 0;
  }

  a {
    font-size: 0.875rem;
    position: absolute;
    top: -0.375rem;
  }
`;

const IndexPriceToggle = styled.div`
  margin: 0.5rem 0;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    text-align: center;
  }
`;

const FootNoteText = styled.h4`
  &&& {
    font-size: 0.6rem;
    letter-spacing: 0;
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding: 0;
    line-height: 1;
    text-transform: unset;
  }
`;

const IndexFuturesLink = styled(TimeFrameLink)`
  font-weight: ${prop('theme.fontWeights.light')};
`;

const propTypes = {
  indexTooltip: PropTypes.array,
  isMobile: PropTypes.bool,
  symbolObject: PropTypes.object,
};

const defaultProps = {
  indexTooltip: [],
  isMobile: false,
  symbolObject: {},
};

function Feeds({ indexTooltip, isMobile, symbolObject }) {
  const [firstSetData, setFirstSetData] = useState({});
  const [secondSetData, setSecondSetData] = useState({});
  const [showSecondSet, setShowSecondSet] = useState(false);
  const indexSymbols = [];
  const futuresSymbols = [];

  let monthOptions = null;
  let firstSetSymbols;
  let secondSetSymbols;

  Object.keys(symbolObject).forEach((key, index) => {
    if (symbolObject[key].data_source === DATA_SOURCE_INDEX) {
      indexSymbols.push(key);
    } else {
      futuresSymbols.push(symbolObject[key].symbol);
    }
  });

  if (futuresSymbols.length > 0) {
    const date = new Date();
    const expDate = findContractExpirationDate(5, 3, date);
    const frontBackMonth = findFrontBackMonth(expDate);
    const frontMonth = first(frontBackMonth);
    const backMonth = last(frontBackMonth);

    monthOptions = frontBackMonth;

    const frontFuturesSymbols = buildFuturesBySymbol(
      frontMonth,
      futuresSymbols
    );
    const backFuturesSymbols = buildFuturesBySymbol(backMonth, futuresSymbols);
    firstSetSymbols = frontFuturesSymbols.split(',');
    secondSetSymbols = backFuturesSymbols.split(',');

    if (indexSymbols.length > 0) {
      firstSetSymbols.push(...indexSymbols);
      secondSetSymbols.push(...indexSymbols);
    }
  } else {
    firstSetSymbols = Object.keys(symbolObject);
    secondSetSymbols = null;
  }

  const symbolsToLoad = [...firstSetSymbols, ...(secondSetSymbols || [])];

  const room = `caxy/rest/eventSource.json?events=Summary,Profile,Trade&symbols=${symbolsToLoad}&lastEvent=1`;

  useSocket(room, message => {
    const tempFirstSetData = {};
    const tempSecondSetData = {};

    firstSetSymbols.forEach(symbol => {
      tempFirstSetData[symbol] = message[symbol];

      if (symbol.startsWith('/')) {
        const trimSymbol = trimFuturesSymbols(symbol);

        Object.assign(tempFirstSetData[symbol].Profile, {
          sortOrder: symbolObject[trimSymbol]
            ? symbolObject[trimSymbol].sort_order
            : null,
          meta: symbolObject[trimSymbol] ? symbolObject[trimSymbol].meta : null,
          dataSource: symbolObject[trimSymbol]
            ? symbolObject[trimSymbol].data_source
            : null,
        });
      } else {
        Object.assign(tempFirstSetData[symbol].Profile, {
          sortOrder: symbolObject[symbol]
            ? symbolObject[symbol].sort_order
            : null,
          meta: symbolObject[symbol] ? symbolObject[symbol].meta : null,
          dataSource: symbolObject[symbol]
            ? symbolObject[symbol].data_source
            : null,
        });
      }
    });

    secondSetSymbols &&
      secondSetSymbols.forEach(symbol => {
        tempSecondSetData[symbol] = message[symbol];
        const profileData =
          tempSecondSetData[symbol] && tempSecondSetData[symbol].Profile;

        if (symbol.startsWith('/')) {
          const trimSymbol = trimFuturesSymbols(symbol);

          profileData &&
            Object.assign(profileData, {
              sortOrder: symbolObject[trimSymbol]
                ? symbolObject[trimSymbol].sort_order
                : null,
              meta: symbolObject[trimSymbol]
                ? symbolObject[trimSymbol].meta
                : null,
              dataSource: symbolObject[trimSymbol]
                ? symbolObject[trimSymbol].data_source
                : null,
            });
        } else {
          Object.assign(profileData, {
            sortOrder: symbolObject[symbol]
              ? symbolObject[symbol].sort_order
              : null,
            meta: symbolObject[symbol] ? symbolObject[symbol].meta : null,
            dataSource: symbolObject[symbol]
              ? symbolObject[symbol].data_source
              : null,
          });
        }
      });

    setFirstSetData(tempFirstSetData);
    setSecondSetData(tempSecondSetData);
  });

  const sortedData = keyBy(
    sortBy(Object.values(showSecondSet ? secondSetData : firstSetData), [
      'Profile.prismicSymbol',
      'Profile.sortOrder',
    ]),
    'Profile.eventSymbol'
  );

  let triggerValue = 'index-price-' + Math.floor(Math.random() * 200);
  const lastTradeTime =
    moment(findLastTradeTime(Object.values(sortedData))) || null;

  return (
    sortedData && (
      <>
        <IndexPriceHeader>
          Prices
          <Tooltip content={indexTooltip} triggerValue={triggerValue} />
        </IndexPriceHeader>
        <IndexPriceToggle>
          {monthOptions && monthOptions.length > 0 && (
            <>
              <IndexFuturesLink
                onClick={() => {
                  setShowSecondSet(false);
                }}
                active={!showSecondSet}
                className={'history-toggle'}
              >
                {monthOptions[0]}
              </IndexFuturesLink>
              {' / '}
              <IndexFuturesLink
                onClick={() => {
                  setShowSecondSet(true);
                }}
                active={showSecondSet}
                className={'history-toggle'}
              >
                {monthOptions[1]}
              </IndexFuturesLink>
            </>
          )}
          <FootNoteText>
            as of &nbsp;
            {formatTime(lastTradeTime)}
          </FootNoteText>
        </IndexPriceToggle>
        {isMobile && (
          <CarouselPanels data={sortedData} symbolObject={symbolObject} />
        )}
        {!isMobile &&
          Object.keys(sortedData).map(feed => {
            const trimSymbol = trimFuturesSymbols(feed);

            if (!sortedData) {
              return null;
            }

            return (
              <Panel
                key={`panel_${feed}`}
                data={sortedData[feed]}
                futuresData={
                  sortedData[feed].Profile.dataSource === DATA_SOURCE_FUTURES
                }
                prismicSymbol={
                  sortedData[feed].Profile.dataSource === DATA_SOURCE_FUTURES
                    ? symbolObject[trimSymbol] &&
                      symbolObject[trimSymbol].symbol
                    : symbolObject[feed] && symbolObject[feed].symbol
                }
                prismicTitle={
                  sortedData[feed].Profile.dataSource === DATA_SOURCE_FUTURES
                    ? symbolObject[trimSymbol] && symbolObject[trimSymbol].title
                    : symbolObject[feed] && symbolObject[feed].title
                }
              />
            );
          })}
      </>
    )
  );
}

Feeds.propTypes = propTypes;
Feeds.defaultProps = defaultProps;

export default Feeds;
